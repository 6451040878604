import React, { Component } from 'react';
import { Bubble } from './Bubble';
import { Heart } from './Heart';
import { NewHeart } from './newheart';
import { PopUp } from './Popup';

import { HubConnectionBuilder, HttpTransportType } from '@aspnet/signalr';
import ErrorPopup from './errorpopup';
import {Exploder} from './exploder';

export class BubbleContainer extends Component {


    fetch_retry = async (url, options, n) => {
        try {
            return await fetch(url, options)
        } catch (err) {
            if (n === 1) throw err;
            return await this.fetch_retry(url, options, n - 1);
        }
    };

    timer=-1;
     hostname = "https://filerzh.azurewebsites.net";
    // hostname = "https://localhost:5003";

constructor(props) {
    super(props);
    this.state = { currentCount: 0, bubbles:[] ,newBubbleValue:"",connectionStarted:false,adjustedHeight:"80%",bubbleLikes:[],hearts:[],showError:false};

      this.addBubbleStore = this.addBubbleStore.bind(this);
      this.addBubble = this.addBubble.bind(this);
      this.initialiseBubbles = this.initialiseBubbles.bind(this);
      this.handleNewBubbleValueChange = this.handleNewBubbleValueChange.bind(this);
      this.unselectAll = this.unselectAll.bind(this);
      this.selectBubble = this.selectBubble.bind(this);
      this.onLikeHandler = this.onLikeHandler.bind(this);
      this.onDislikeHandler = this.onDislikeHandler.bind(this);
      this.deleteBubbleStore = this.deleteBubbleStore.bind(this);
      this.handleNewLike = this.handleNewLike.bind(this);
      this.onErrorClick = this.onErrorClick.bind(this);
      this.onTouchMove = this.onTouchMove.bind(this);
      this.newHeart = this.newHeart.bind(this);
      
      try {
          this.hubConnection = new HubConnectionBuilder()
              //.withUrl('https://localhost:5003/bubbleTextHub', {
              .withUrl(this.hostname+'/bubbleTextHub', {
                  skipNegotiation: true,
                  transport: HttpTransportType.WebSockets,
              })
              .build();
          this.hubConnection
              .start()
              .then(() => {
                  this.hubConnection.on('newBubbleText', (data) => {
                      this.setState({
                          bubbles: [...this.state.bubbles, data]
                      });
                  });
                  this.hubConnection.on('newBubbleLike', (data) => {
                    
                    this.handleNewLike(data);
                    console.log(JSON.stringify(data));
                    });
                
                  this.setState({ connectionStarted: true });

                  this.hubConnection.invoke('initialise');
              });
      }
      catch (e) {
      }
      this.initialiseBubbles();
      
    }

    handleNewLike(data)
    {
    
        
        if (data.likes>0)
        {
            if (data!=null)
            {
                var bubbleItem=document.getElementById("bubble_"+data.bubbleTextId);
                var boundingRect = bubbleItem.getBoundingClientRect();
                
                var heart=new NewHeart();
                heart.explode(
                    boundingRect.x+(boundingRect.width/2),
                    boundingRect.y+(boundingRect.height/2),
                    400,
                    1000,
                    20);
            } 
        }
        // update data
        var bubble = this.state.bubbles.find(bub=>bub.id==data.bubbleTextId);
        if (bubble!=null)
        {
            if (data.likes>0)
            {
                bubble.likes +=data.likes;
            }
            if (data.dislikes>0)
            {
                bubble.dislikes +=data.dislikes;
            }
            this.setState({
                bubbles: this.state.bubbles
            });
        }
        
    }
    newHeart(xpos,ypos){
        var colorsForHearts=[
            "#6dcbe8",
            "#ff6060",
            "#addf76",
            "#4ee2bf",
            "#da6aee"];
        var color=colorsForHearts[parseInt(Math.random()*parseFloat(colorsForHearts.length))]  ;      
            // add heart at location
     return {userId:this.props.userId,x:xpos,bottom_y:ypos,animation_delay:4,color:color};

     
    }
    addBubble() {

        const Sentiment = require('sentiment');
        const sentiment = new Sentiment();

        // Get user input
        let userInput = this.state.newBubbleValue;

        // Analyze the sentiment of the input
        let result = sentiment.analyze(userInput);

        // Print the results
        console.log(result);
 
        if (result.score>0) 
        {
            this.addBubbleStore(this.state.newBubbleValue, this.props.userId, 0, 0);
            this.setState({ newBubbleValue: ""  });
        }
        else {
            this.setState({ showError: true });
        }

    }

    async addBubbleStore(text,userId,likes,dislikes) {

        var response = await this.fetch_retry(this.hostname + "/api/BubbleText/",
            //    this.fetch_retry("https://localhost:5003/api/BubbleText/",
            {
                method: "PUT",
                body: JSON.stringify({
                    "text": text,
                    "userId": userId,
                    "likes": likes,
                    "dislikes": dislikes
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }

            }, 5);


        if (response.status == 200) {

            const data = await response.json();
                console.log("Successfully sent results");
                this.setState({
                    bubbles: [...this.state.bubbles, { text: text, likes: likes, dislikes: dislikes, selected: false, userId: userId,id:data.id,isNew:true }]
                });
            }
        

       

    }
    deleteBubbleStore(id,element) {
        if (element!=null)
        {
            var bubbleItem=document.getElementById("bubble_"+element.props.id);
            var boundingRect = bubbleItem.getBoundingClientRect();
            
            var myExploder=new Exploder();
            myExploder.explode(
                boundingRect.x+(boundingRect.width/2),
                boundingRect.y+(boundingRect.height/2),
                boundingRect.width*2,
                boundingRect.height*2,
                100);
        }
        this.fetch_retry(this.hostname+"/api/BubbleText?userId="+this.props.userId+"&id="+id,
            //    this.fetch_retry("https://localhost:5003/api/BubbleText/",
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }

            }, 5).then(response => {

                if (response.status == 200) {

                    console.log("Successfully deleted " + id);
                    var index = this.state.bubbles.findIndex((e) => e.id == id);
                    if (index > -1) {
                        var array = this.state.bubbles;
                        array.splice(index, 1);
                        this.setState({ bubbles: array });
                    }

                }
                else {
                    console.log("Error deleting " +id);
                }
            });

        
    }
   async initialiseBubbles() {
       var dataWithPosition = [];
       var response = await this.fetch_retry(this.hostname+"/api/BubbleText/allResultsFromId?id=0&userId="+this.props.userId,
           //   var response = await this.fetch_retry("https://localhost:5003/api/BubbleText/allResults",
           {
               method: "GET",

               headers: {
                   Accept: "application/json",
                   "Content-Type": "application/json"
               }

           }, 5);
       

       if (response.status == 200) {

        var likesBoundry=[-999,-10,-3,0,1,5,10,20,50];
        
        var likesSizes=[10,20,30,40,50,60,70,80,100];
           const data = await response.json();
           var
               max_bubbleSize = 10,
               min_bubbleSize = 20;

           dataWithPosition = data.map(function (o, i) {

            var sizelookup=likesSizes[likesBoundry.findIndex(fi=>fi>(o.likes-o.dislikes))];
            if (o.mydislikes>0) sizelookup=sizelookup/2;
               var bubble_size = sizelookup + Math.floor(Math.random() * min_bubbleSize + 1);
               var bubble_pos = Math.floor(Math.random() * 70 + 1);
               var blur_size = 0 + Math.floor(Math.random() * 3 + 1);
               var animation_delay = 10 + Math.floor(Math.random() * 18);
               var random_margin = 50 + Math.floor(Math.random() * 100);
               o.bubble_size = bubble_size;
               o.bubble_pos = bubble_pos;
               o.blur_size = blur_size;
               o.animation_delay = animation_delay;
               o.random_margin = random_margin;
               o.selected = false;
               o.isNew=true;
               return o;
           });

         


           console.log("Successfully received results");


           var response2 = await this.fetch_retry(this.hostname + "/api/BubbleLike/allMyLikes?userId=" + this.props.userId,
               //   var response = await this.fetch_retry("https://localhost:5003/api/BubbleText/allResults",
               {
                   method: "GET",

                   headers: {
                       Accept: "application/json",
                       "Content-Type": "application/json"
                   }

               }, 5);


           if (response2.status == 200) {

               const data = await response2.json();
               data.forEach(likes => {
               
                   var item = dataWithPosition.find(dp => likes.bubbleTextId == dp.id);
                   
                   if (item != null) {
                       item.mylikes = likes.likes;
                       item.mydislikes = likes.dislikes;
                       item.isNew=false;
                   }
               });
               var filteredDataWithPosition= dataWithPosition.filter(function(value, index, arr){ 
                return value.mydislikes==null || value.mydislikes == 0;
            });
               this.setState({
                   bubbleLikes: data
               });
               this.setState({
                   bubbles: filteredDataWithPosition
               });

               console.log("Successfully received results");
           }


       }

    }
    handleNewBubbleValueChange(e) {
        this.setState({ newBubbleValue: e.target.value });
    }

    unselectAll() {
        
                var result = this.state.bubbles.map(function (o, i) {
                    o.selected = false;
                    return o;
                });
        this.setState({
            bubbles:  result 
        });
       

    }
    
    selectBubble(id) {

        var result = this.state.bubbles.map(function (o, i) {
            if (o.id == id){ o.selected = true;o.isNew=false;}
            return o;
        });
        this.setState({
            bubbles: result
        });
        if (this.timerId>0) clearTimeout(this.timerId);
        this.timerId=setTimeout(()=>{this.unselectAll();this.timerId=-1;},10000);
    }
    
    onTouchMove(e,bubble){
        
    }

    onLikeHandler(id,element) {

        if (element!=null)
        {
            var bubbleItem=document.getElementById("bubble_"+element.props.id);
            var boundingRect = bubbleItem.getBoundingClientRect();
            
            var heart=new NewHeart();
            heart.explode(
                boundingRect.x+(boundingRect.width/2),
                boundingRect.y+(boundingRect.height/2),
                boundingRect.width*2,
                boundingRect.height*5,
                20);
        }


        this.fetch_retry(this.hostname+"/api/BubbleLike/",
            //    this.fetch_retry("https://localhost:5003/api/BubbleText/",
            {
                method: "PUT",
                body: JSON.stringify({
                    "bubbleTextId": id,
                    "userId": this.props.userId,
                    "likes": 1,
                    "dislikes": 0
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }

            }, 5).then(response => {
                console.log("Successfully sent like");
            });

    }
    onDislikeHandler(id,element) {
        if (element!=null)
        {
            var bubbleItem=document.getElementById("bubble_"+element.props.id);
            var boundingRect = bubbleItem.getBoundingClientRect();
            
            var myExploder=new Exploder();
            myExploder.explode(
                boundingRect.x+(boundingRect.width/2),
                boundingRect.y+(boundingRect.height/2),
                boundingRect.width*2,
                boundingRect.height*2,
                100);
        }
        var index = this.state.bubbles.findIndex((e) => e.id == id);
        if (index > -1) {
            var array = this.state.bubbles;
            array.splice(index, 1);
            this.setState({ bubbles: array });
        }
        this.fetch_retry(this.hostname+"/api/BubbleLike/",
            //    this.fetch_retry("https://localhost:5003/api/BubbleText/",
            {
                method: "PUT",
                body: JSON.stringify({
                    "bubbleTextId": id,
                    "userId": this.props.userId,
                    "likes": 0,
                    "dislikes": 1
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }

            }, 5).then(response => {
                console.log("Successfully sent like");
            });

    }
    onErrorClick() {
        this.setState({ showError: false });
      }
    render() {
      
        
        var that = this;
    return (

        <div className="container">
            <input style={{ width: "100%" }}
                type="text" placeholder="post something blissful.."
                value={this.state.newBubbleValue}
                onChange={this.handleNewBubbleValueChange} />
            {this.state.showError && <ErrorPopup errorText="Please make sure your post is positive" onClick={this.onErrorClick} />}
            <button className="btn btn-primary" onClick={this.addBubble}>Add bubble</button>
            <div className="content" style={{ height: this.state.adjustedHeight+"px" }}  >
                {this.state.bubbles.map(function (o, i) {
                    return <Bubble key={"bubbleitem-" + i} text={o.text}  isNew={o.isNew} onDeleteHandler={that.deleteBubbleStore} onLikeHandler={that.onLikeHandler} onDislikeHandler={that.onDislikeHandler} mylikes={o.mylikes} mydislikes={o.mydislikes} userId={o.userId} likes={o.likes} dislikes={o.dislikes} id={o.id} unselectAll={that.unselectAll} selectBubble={that.selectBubble} bubble_size={o.bubble_size} bubble_pos={o.bubble_pos} blur_size={o.blur_size} animation_delay={o.animation_delay} random_margin={o.random_margin} opened={o.selected} />
                })}
                {this.state.hearts.map(function (o, i) {
                    return <Heart key={"heartitem-" + i} userId={o.userId}  position_x={o.x} bottom_y={o.bottom_y} color={o.color} animation_delay={o.animation_delay} />
                })}
            </div>
      </div>
    );
  }
}
