//- Explosion
//- adapted from "Anchor Click Canvas Animation" by Nick Sheffield
//- https://codepen.io/nicksheffield/pen/NNEoLg/

export class Exploder{
    constructor(){

    }

   
     explode = (x, y,width,height,numOfBubbles) => {
         var colors = [ '#44c0ff', '#443b3b', '#4484ff' ];
        var bubbles = numOfBubbles;

        let particles = [];
        let ratio = window.devicePixelRatio;
        let c = document.createElement('canvas');
        let ctx = c.getContext('2d');

        c.style.position = 'absolute';
        c.style.left = (x - (width/2)) + 'px';
        c.style.top = (y - (height/2)) + 'px';
        c.style.pointerEvents = 'none';
        c.style.width = width + 'px';
        c.style.height = height + 'px';
        c.style.zIndex = 100;
        c.width = width * ratio;
        c.height = height * ratio;
        document.body.appendChild(c);

        for(var i = 0; i < bubbles; i++) {
            particles.push({
                x: c.width / 2,
                y: c.height / 2,
                radius: this.r(20, height/2),
                color: colors[Math.floor(Math.random() * colors.length)],
                rotation: this.r(0, 360, true),
                speed: this.r(8, 12),
                friction: 0.9,
                opacity: this.r(0, 0.5, true),
                yVel: 0,
                gravity: 0.1
            });
        }

        this.render(null,c,particles, ctx, c.width, c.height);
    }

    startTimestamp=null;
    previousTimeStamp=null;

     render(timestamp,c,particles, ctx, width, height) {
        if (this.startTimestamp==null) 
        {
            this.startTimestamp=timestamp;
        }
        if (this.previousTimeStamp !== timestamp || this.previousTimeStamp==null) 
        {
        
            const elapsed = timestamp - this.startTimestamp;
            if (elapsed > 5000 && this.startTimestamp!=null) { // Stop the animation after 2 seconds
                document.body.removeChild(c);
                this.startTimestamp=null;    
                this.previousTimestamp=null;
            }
            else{
                this.previousTimeStamp=timestamp;
                requestAnimationFrame((time) => this.render(time,c,particles, ctx, width, height));
                ctx.clearRect(0, 0, width, height);

                particles.forEach((p, i) => {
                    p.x += p.speed * Math.cos(p.rotation * Math.PI / 180);
                    p.y += p.speed * Math.sin(p.rotation * Math.PI / 180);

                    p.opacity -= 0.01;
                    p.speed *= p.friction;
                    p.radius *= p.friction;
                    p.yVel += p.gravity;
                    p.y += p.yVel;

                    if(p.opacity < 0 || p.radius < 0) return;

                    ctx.beginPath();
                    ctx.globalAlpha = p.opacity;
                    ctx.fillStyle = p.color;
                    ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, false);
                    ctx.fill();
                });
            }
        }
        return ctx;
        
    }

     r = (a, b, c) => parseFloat((Math.random() * ((a ? a : 1) - (b ? b : 0)) + (b ? b : 0)).toFixed(c ? c : 0));

    //document.querySelector('.js-explosion').addEventListener('mouseover', e => explode(e.pageX, e.pageY));
}