import React, { Component } from 'react';
import { Fragment } from 'react'; // So it doesn't create a unnecessary node element. **Only available in react 16+

export class Heart extends Component {


  constructor(props) {
    super(props);

       this.state = { isLiked: this.props.mylikes > this.props.mydislikes, isDisliked: this.props.mylikes < this.props.mydislikes };
  }

    onClick(e) {
        e.stopPropagation();  //  <------ Here is the magic
  }
   

   
   

    render() {

        var containerStyle = {};
        console.log("heart bottom px="+this.props.bottom_y);
        containerStyle = {

          'left': this.props.position_x,
          'bottom': this.props.bottom_y+"px",
            
            '-webkit-animation-duration': this.props.animation_delay + 's',
            '-moz-animation-duration': this.props.animation_delay + 's',
            'animation-duration': this.props.animation_delay + 's',
            'animation-fill-mode': 'forwards',
            'animation-play-state':  'running'


        };
    
        
        var bubbleStyle = {};
       
             bubbleStyle = {
              'color':this.props.color,
                'scale': this.props.scale,
                'width': '200px',
                'height': '200px',
                'text-align': 'center',
                'vertical-align': 'middle',
                
  'animation-play-state': 'running'
            };
        

        var bubbleClass = "bubble";
      return (
          <div className="heart-container" style={containerStyle}>
            
                 <div  style={bubbleStyle} onClick={this.onClick} onDoubleClick={this.onDoubleClick} >
              <div style ={{ 'position': 'absolute', 'top': '0px', 'display': 'flex' }}>
          
                    <div className={"wrapper is-active"} >
                        <i className="material-icons is-liked bouncy paragraph">favorite</i>
                        <span className="like-overlay"></span>
                        
                    </div>
                </div>
               
               
        
                      
                
              </div>
              </div>
    );
  }
}
