//import React, { Component } from 'react';
import { BubbleContainer } from './components/BubbleContainer';

import './custom.css'
import React, { useState } from 'react';
import { GoogleLogin} from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import jwtDecode from 'jwt-decode'


const clientId = "775134145849-oo5ag89uu9ph8l2knqlj9uebth760nrf.apps.googleusercontent.com";

function App() {

    const [loading, setLoading] = useState('Loading...');
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);

    // ...
    // write a method to handle events
    // ...
    const handleLoginSuccess = (response) => {
        console.log("Login Success ", response);
        
        if (response.credential != null) {
            const  dataCredential = jwtDecode(response.credential);
            setUser(dataCredential);
            setToken(dataCredential.email);   
            setLoading();
        }
    }

    const handleLoginFailure = error => {
        console.log("Login Failure ", error);
        setLoading();
    }

    const handleLogoutSuccess = (response) => {
        console.log("Logout Success ", response);
        setUser(null);
    }

    const handleLogoutFailure = error => {
        console.log("Logout Failure ", error);
    }

    const handleRequest = () => {
        setLoading("Loading...");
    }

    const handleAutoLoadFinished = () => {
        setLoading();
    }

    const local = () => {
        var full = window.location.host;
        var parts = full.split('.');
        var subdomain = parts[0];
        return (full.startsWith("192.168.2.174") || subdomain == "localhost"|| full.endsWith("gitpod.io"));
    }


    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })

        }

        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)

        }
    })

    return (
        <GoogleOAuthProvider clientId={clientId}>
        <div>
            {user!=null ? <div>
                <div className="name">{user.name}</div>
      

                </div>
                
             :
             <div><h3>Login to Bubbles with Google  - Beta</h3>
            
                <GoogleLogin
                    clientId={clientId}
                    onSuccess={handleLoginSuccess}
                    onFailure={handleLoginFailure}
                    auto_select
                    useOneTap               
                /></div>}

            {(user!=null || local()) ? 
                    <div><BubbleContainer userId={token} dimensions={dimensions} /></div>
                : <div/>
                }
        </div>
        </GoogleOAuthProvider>
    );
    
}

export default App;

